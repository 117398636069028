import axios from '@axios'

export default {
  namespaced: true,
  state: {
    users: {
      rows: [],
      totalRecords: 0,
    },
  },
  /* eslint-disable camelcase */
  getters: {},
  mutations: {
    setUsers(state, users) {
      state.users = users
    },
    deleteUser(state, userId) {
      const usersData = {
        rows: state.users.rows.filter(user => user.id !== userId),
        totalRecords: state.users.totalRecords - 1,
      }
      state.users = usersData
    },
  },
  actions: {
    fetchUsers(store, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user-list/data', { params: queryParams })
          .then(response => {
            store.commit('setUsers', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/get-user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    validateNewName(ctx, { new_name, user_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('user/name-change-validate', { new_name, user_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    rejectRename(ctx, user_id) {
      return new Promise((resolve, reject) => {
        axios
          .post('user/name-change-reject', { user_id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/store', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/user/update', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(store, userData) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/user/delete', { data: { user_id: userData } })
          .then(response => {
            store.commit('deleteUser', userData)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
